import Lset from 'lodash/set'
import Lget from 'lodash/get'
import { isNull } from 'lodash'
import apiClient from '@/lib/unlogin/store/apiclient'
import HierarchiesPivotTableCell from '../HierarchiesPivotTableCell/HierarchiesPivotTableCell.vue'
import { EventBus } from '@/apps/core/helpers/event-bus'
import { mapGetters } from 'vuex'

export default {
  name: 'HierarchiesPivotTableData',
  components: { HierarchiesPivotTableCell },
  props: {
    columnFields: {
      type: Object,
      description: 'The internal structure of the PivotTable cells',
      required: true
    },
    cellsEndpoint: {
      type: String,
      description: 'The endpoint used to get the cell elements',
      required: true
    },
    rowsEndpoints: {
      type: Array,
      description: 'The endpoint used to get the row elements',
      required: true
    },
    columnsEndpoints: {
      type: Array,
      description: 'The endpoint used to get the column elements',
      required: true
    },
    rowIdentifier: {
      type: String,
      description: 'The attribute from the cellsEndpoint that references the row hierarchy element',
      required: true
    },
    columnIdentifier: {
      type: String,
      description: 'The attribute from the cellsEndpoint that references the column hierarchy element',
      required: true
    },
    rowLabel: {
      type: String,
      description: 'The label to be used at the header that references the row elements',
      required: true
    },
    /**
     * To be called by the HPT-Data's orderedRows to determine whether a cell is editable
     * @param pivotTableData {Object} HPT-Data "this" instance
     * @param rowHierarchyElement {Object} Row hierarchy element that the cell belongs to
     * @param cell {Object} The cell instance
     * @returns {boolean}
     */
    isCellEditable: {
      type: Function,
      description: 'Callback to compute whether a cell will be editable or not',
      required: true
    },
    customChartData: {
      type: [Function, null],
      description: 'Callback to compute whether a cell will be editable or not',
      default: null
    },
    customCellFormatters: {
      type: Object,
      description: '[field name] -> {fmt: formatter (arrow func), suffix: (Which can be empty)',
      default: null
    },
    customCellEdition: {
      type: [Function, null],
      description: 'Passed to HPT-Cell: Callback to customise cell data edition',
      default: null
    },
    stickyColumn: {
      type: Boolean,
      default: true
    },
    adaptableHeight: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      loadingTable: false,
      cellUpdating: 0,
      updatedCells: [],
      // columnFields: {}
      cellData: [],
      rowsHierarchy: [],
      columnsHierarchy: [],
      adaptableStyles: ''
    }
  },
  async mounted () {
    await this.fetchData()
    EventBus.$on('hideNoSales', (state) => this.hideSales(state))
  },
  computed: {
    ...mapGetters('forecasting', { 'showZeroItems': 'getForecastZeroItems' }),

    stickyColumnStyles () {
      return this.stickyColumn ? 'fixed' : ''
    },
    tableHeight () {
      if (this.adaptableHeight) {
        this.adaptableStyles = 'no-scroll'
        return 'auto'
      } else {
        this.adaptableStyles = ''
        return window.innerHeight - 75
      }
    },
    /**
   * Builds headers array to form the headers slots
   * @returns {{text: *, value: string}[]}
   */
    headers () {
      return this.columnsHierarchy.map(column => ({ value: column.external_id, text: column.name }))
    },
    /**
   * Adds the rowLabel/name header to the visible headers (As to omit it from the dynamic header slots)
   * @returns {{text: *, value: string}[]}
   */
    headersWithRowName () {
      let headers = this.headers.slice()
      headers = headers.filter(header => Object.keys(this.columnFields).includes(header.value))
      headers.unshift({ text: this.rowLabel, value: 'name', class: this.stickyColumnStyles })
      return headers
    },
    /**
   * Used to generate the dynamic header slots with their respective fields according to the columnFields prop
   * @returns {{headerSlot: string, fields: *}[]}
   */
    headersSlots () {
      return this.headers.map(column => ({ headerSlot: `header.${column.value}`, fields: this.getCellMetadata(column.value) }))
    },
    /**
   * Used to generate the dynamic item slots with their respective fields
   * @returns {{value: string, itemSlot: string}[]}
   */
    itemsSlots () {
      return this.headersWithRowName.map(column => ({ itemSlot: `item.${column.value}`, value: column.value }))
    },
    /**
   * Iterates over all flattenedRowsHierarchy to obtain the minimum and maximum depths
   * @returns {{min: null, max: null}}
   */
    rowsHierarchyDepthRange () {
      let depthRange = { min: null, max: null }
      this.flattenedRowsHierarchy.forEach(hierarchyElement => {
        if (isNull(depthRange.min) && isNull(depthRange.max)) {
          depthRange = { min: hierarchyElement.level_depth, max: hierarchyElement.level_depth }
        } else if (hierarchyElement.level_depth > depthRange.max) {
          depthRange.max = hierarchyElement.level_depth
        } else if (hierarchyElement.level_depth < depthRange.min) {
          depthRange.min = hierarchyElement.level_depth
        }
      })
      return depthRange
    },
    /**
   * DEPRECATED
   * Orders 'rowsFromCellData' according to 'flattenedRowsHierarchy' and merges its HierarchyElement row data (name & level)
   * @returns {Array} items to be rendered at the table
   */
    orderedRows () {
      const orderedRows = []
      this.flattenedRowsHierarchy.forEach(hierarchyElement => {
        let tableRow = this.rowsFromCellData.find(row => row.id === hierarchyElement.id)
        if (tableRow) {
          Object.entries(this.columnFields).forEach(([ column ]) => {
            if (tableRow[column]) {
              tableRow[column].editableCell = this.isCellEditable(this, hierarchyElement, tableRow[column], column)
            }
          })
          orderedRows.push({ ...hierarchyElement, ...tableRow })
        }
      })

      if (this.showZeroItems) {
        const final = []
        orderedRows.forEach(row => {
          const filteredByTotalSale = Object.entries(row).some(data => data[1]?.total_sales > 0)
          if (filteredByTotalSale) final.push(row)
        })
        return final
      }

      return orderedRows
    },
    /**
   * Returns all HierarchyElements from 'rowsHierarchy' flattened into an array.
   * @returns {{name, id, level_depth}}
   */
    flattenedRowsHierarchy () {
      return this.recursiveFlatteningRowsHierarchy(this.rowsHierarchy)
    },
    /**
   * Cross product between rows and columns to generate the table rows. These are later ordered according to 'flattenedRowsHierarchy'
   * @returns {Array}
   */
    rowsFromCellData () {
      let rows = []
      this.cellData.forEach(cell => {
        let columnId = cell[this.columnIdentifier]
        let columnElement = this.columnsHierarchy.find(element => element.id === columnId)
        if (columnElement) {
          let columnExternalId = columnElement.external_id
          let rowElementIndex = rows.findIndex(row => row.id === cell[this.rowIdentifier])
          if (rowElementIndex === -1) {
            let newRow = { id: cell[this.rowIdentifier] }
            this.fillRowWithCellData(newRow, columnExternalId, cell)
            rows.push(newRow)
          } else {
            let existingRow = rows[rowElementIndex]
            this.fillRowWithCellData(existingRow, columnExternalId, cell)
          }
        }
      })
      return rows
    }
  },
  watch: {
    orderedRows () {
      this.emitChartData()
    }
  },
  methods: {
    hideSales (state) {
      this.filterBy0 = !state
    },

    /**
   * Event handler for a cell dialog save: Finds cell element changed and propagates it
   * @param item {Object} The item whose cell was modified
   * @param field {string} The field name of the sub-object modified
   * NOTE: Method must be updated to pass the whole item? (this.cellData.find(cell => cell.id === item.id) ?)
   */
    onSaveCell (item, field) {
      let cellInstance = this.cellData.find(cellElement => cellElement.id === item.id)
      let fullCellInstance = { ...cellInstance, ...item[field] }
      delete fullCellInstance.metadata
      this.cellUpdating = fullCellInstance.id
      this.cleanUpdatedCells()
      this.$emit('saveCell', fullCellInstance)
    },
    /**
   * To be called through $refs or differently as to stop the cell's spinner
   */
    isUpdateFinished () {
      this.cellUpdating = 0
    },
    /**
   * Cleans 'isCellUpdated' attribute from each cell, so when "refreshUpdatedLines" is called again, the changed cells will blink again
   */
    cleanUpdatedCells () {
      this.updatedCells.forEach(updatedCellId => {
        let updatedCell = this.cellData.find(cell => cell.id === updatedCellId)
        if (updatedCell) {
          updatedCell.isCellUpdated = false
        }
      })
      this.updatedCells = []
    },
    /**
   * Watches orderedRows values as to emit updated chart data to the HPT Base
   */
    emitChartData () {
      if (this.customChartData) {
        let chartData = this.customChartData(this)
        this.$emit('chartData', chartData)
      }
    },
    /**
   * To be used whenever a save/update fails, as to reset to previous cell value
   * @param cellId {number}
   */
    undoUpdate (cellId) {
      this.$refs[`cell${cellId}`][0].undoUpdate()
    },
    /**
   * DEPRECATED
   * Recursive method to generate 'flattenedRowsHierarchy' from the rowsHierarchy
   * @param hierarchyElementList {Array} a HierarchyLevel with its respective elements
   * @returns {*}
   */
    recursiveFlatteningRowsHierarchy (hierarchyElementList) {
      return hierarchyElementList.reduce((flattenedHierarchy, element) => {
        flattenedHierarchy.push({ name: element.name, id: element.id, level_depth: element.hierarchy_level.depth })
        if (element.children) {
          let flattenedSubHierarchy = this.recursiveFlatteningRowsHierarchy(element.children)
          flattenedHierarchy = flattenedHierarchy.concat(flattenedSubHierarchy)
        }
        return flattenedHierarchy
      }, [])
    },
    /**
   * Recursive method to generate 'flattenedRowsHierarchy' from the rowsHierarchy: Final form of the table items
   * TODO: Hopefully delete this method (Only needed when parent's values are calculated in the front-end)
   * @param hierarchyElementList {Array} a HierarchyLevel with its respective elements
   * @returns {*}
   */
    recursiveFlatteningRowsHierarchyNew (hierarchyElementList) {
      return hierarchyElementList.reduce((flattenedHierarchy, element) => {
        let tableRow = this.rowsFromCellData.find(row => row.id === element.id)

        let flattenedElement = tableRow
          ? { ...tableRow, name: element.name, id: element.id, level_depth: element.hierarchy_level.depth, editableCell: true } // lines existentes
          : { name: element.name, id: element.id, level_depth: element.hierarchy_level.depth, editableCell: false } // lines calculadas
        this.addEditableCellProperty(flattenedElement, tableRow)
        flattenedHierarchy.push(flattenedElement) // filas de la tabla
        if (element.children) {
          let flattenedSubHierarchy = this.recursiveFlatteningRowsHierarchyNew(element.children)
          flattenedHierarchy = flattenedHierarchy.concat(flattenedSubHierarchy)
          let cellData = this.calculateParentCellElementData(element, flattenedSubHierarchy, flattenedElement)
          let currentIndex = flattenedHierarchy.findIndex(hierarchyElement => hierarchyElement.id === element.id)
          let currentElement = flattenedHierarchy[currentIndex]
          let newElement = { ...currentElement, ...cellData }
          flattenedHierarchy.splice(currentIndex, 1, newElement)
        }
        return flattenedHierarchy
      }, [])
    },
    /**
   * Depending on whether a cell element is calculated or exists as an instance, it will be editable
   * TODO: Hopefully delete this method (Only needed when parent's values are calculated in the front-end)
   * @param flattenedElement
   * @param tableRow
   */
    addEditableCellProperty (flattenedElement, tableRow) {
      Object.entries(this.columnFields).forEach(([columnKey, columnField]) => {
        if (flattenedElement[columnKey]) {
          flattenedElement[columnKey].editableCell = !!tableRow
        }
      })
    },
    /**
   * Used to compute the aggregate/sum of the parent element's values from its immediate children
   * TODO: Hopefully delete this method (Only needed when parent's values are calculated in the front-end)
   * @param element - The parent element
   * @param flattenedSubHierarchy - The subhierarchy (children) used to compute the parent element's values
   * @returns {{}}
   */
    calculateParentCellElementData (element, flattenedSubHierarchy, flattenedElement) {
      let cellData = {}
      Object.entries(this.columnFields).forEach(([columnKey, columnField]) => {
        if (flattenedElement[columnKey]) {
          cellData[columnKey] = {}
          columnField.forEach(field => {
            let fieldName = field.value
            let fieldValue = flattenedSubHierarchy.reduce((aggregate, flattenedChild) => {
              if (flattenedChild.level_depth - element.hierarchy_level.depth === 1) {
                cellData[columnKey].metadata = flattenedChild[columnKey].metadata
                let childValue = Lget(flattenedChild[columnKey], fieldName)
                /** NOTE: The following line can be a function prop, as to parametrize the calculation being made. */
                aggregate += childValue
              }
              return Number.parseFloat(aggregate).toFixed(2)
            }, 0)
            Lset(cellData[columnKey], fieldName, fieldValue)
          })
        }
      })
      return cellData
    },
    /**
   * Returns the row's class according to its relative hierarchy level (depth)
   * @param item {Object} the item representing the row
   * @returns {string}
   */
    computeRowLevelClass (item) {
      const maxLevel = this.rowsHierarchyDepthRange.max
      const level = item.level_depth
      const themeColor = this.$vuetify.theme.currentTheme.primary
      let levelColor
      level === maxLevel
        ? levelColor = 0
        : levelColor = level + 1
      const calculatedColor = this.colorChanger(0.15 + (0.15 * levelColor), themeColor, false, true)
      return levelColor > 0
        ? `backgroundColor: ${calculatedColor}`
        : 'backgroundColor: white'
    },
    /**
   * Used by the rowsFromCellData computed property to fill each row/column intersection
   * @param row {Object} Object that represents the row with each of its cell (column) instances
   * @param columnId {number} Desired row's column/field to inject the cell content into
   * @param cell {Object} cell object from the API to be injected in the row
   */
    fillRowWithCellData (row, columnId, cell) {
      let metadata = this.getCellMetadata(columnId)
      if (metadata) {
        row[columnId] = {}
        row[columnId].id = cell.id
        row[columnId].isCellUpdated = cell.isCellUpdated
        row[columnId].metadata = this.getCellMetadata(columnId)
        row[columnId].metadata.forEach(field => {
          Lset(row[columnId], field.value, Lget(cell, field.value))
        })
      }
    },
    /**
   * Get the cell fields to iterate from the cellItem, according to the columnFields prop
   * @param columnIdField {string} which is the hierarchy-element's external_id
   * @returns {Object}
   */
    getCellMetadata (columnIdField) {
      return this.columnFields[columnIdField]
    },
    /**
   * Fetches all table data: rows, columns and cells
   */
    async fetchData () {
      try {
        this.loadingTable = true
        this.cellData = await this.fetchCellData()
        this.rowsHierarchy = await this.fetchRows()
        this.columnsHierarchy = await this.fetchColumns()
      } catch (err) {
        console.error(err)
      } finally {
        this.loadingTable = false
        this.$emit('doneLoading')
      }
    },
    /**
   * Fetches the cells endpoint
   * @returns {Promise<unknown>}
   */
    async fetchCellData () {
      let res = await apiClient.get(this.cellsEndpoint)
      let allCells = res.data.results
      // this.generateColumnFields(allCells)
      return allCells
    },
    /**
   * Refreshes the cells according to PATCH responses, as to avoid a subsequent GET request.
   * @param updatedCells {Array} List of lines that were updated by the patch event
   */
    refreshUpdatedCells (updatedCells) {
      updatedCells.forEach(updatedCell => {
        let index = this.cellData.findIndex(cell => updatedCell.id === cell.id)
        if (index !== -1) {
          this.cellData.splice(index, 1, { ...updatedCell, isCellUpdated: true })
          this.updatedCells.push(updatedCell.id)
        }
      })
    },
    /**
   * Fetches all rows endpoints
   * @returns {Promise<*>}
   */
    fetchRows () {
      return this.fetchTableDimension(this.rowsEndpoints)
    },
    /**
   * Fetches all columns endpoints.
   * @returns {Promise<*>}
   */
    fetchColumns () {
      return this.fetchTableDimension(this.columnsEndpoints)
    },
    /**
   * Generic method for fetching multiple rows/columns endpoints and merge 'em results
   * @param endpoints {Array} Either the rows' or the columns' endpoints
   * @returns {Promise<*>}
   */
    async fetchTableDimension (endpoints) {
      let responses = await Promise.allSettled(endpoints.map(({ url }) => {
        return apiClient.get(url)
      }))
      return responses.reduce((accumulator, response, index) => {
        return this.mergeHierarchies(accumulator, response.value, endpoints[index].responseField)
      }, [])
    },
    /**
   * Merges all the hierarchies fetched through all rows & columns endpoints
   * @param accumulator {Array} The array that accumulates all merged hierarchies so far
   * @param response {Object} The response object to obtain the hierarchies from
   * @param responseField {string} Defines where inside the response object lies the desired hierarchy data
   * @returns {*}
   */
    mergeHierarchies (accumulator, response, responseField) {
      let data = this.Lget(response, responseField)
      accumulator.push(data)
      if (Array.isArray(data)) {
        return accumulator.flat()
      }
      return accumulator
    },
    /**
   * Refreshes table data according to new endpoints.
   * @param property
   */
    async refreshData (property) {
      if (property === 'column') {
        await this.refreshColumnData()
      } else if (property === 'row') {
        await this.refreshRowData()
      } else if (property === 'all') {
        await this.fetchData()
      }
    },
    /**
   * Refresh function for columnsEndpoints changes
   * @returns {Promise<void>}
   */
    async refreshColumnData () {
      this.loadingTable = true
      Promise.allSettled([
        this.fetchCellData(),
        this.fetchColumns()
      ]).then((responses) => {
        this.cellData = responses[0].value
        this.columnsHierarchy = responses[1].value
        this.loadingTable = false
        this.$emit('doneLoading')
      })
    },
    /**
   * Refresh function for rowsEndpoints changes
   * @returns {Promise<void>}
   */
    async refreshRowData () {
      this.loadingTable = true
      Promise.allSettled([
        this.fetchCellData(),
        this.fetchRows()
      ]).then((responses) => {
        this.cellData = responses[0].value
        this.rowsHierarchy = responses[1].value
        this.loadingTable = false
        this.$emit('doneLoading')
      })
    },
    /* eslint-disable */
  colorChanger (p, c0, c1, l) {
    let r, g, b, P, f, t, h, i = parseInt, m = Math.round, a = typeof (c1) == "string"
    if (typeof (p) != "number" || p < -1 || p > 1 || typeof (c0) != "string" || (c0[0] != 'r' && c0[0] != '#') || (c1 && !a)) return null
    if (!this.pSBCr) this.pSBCr = (d) => {
      let n = d.length, x = {}
      if (n > 9) {
        [r, g, b, a] = d = d.split(","), n = d.length;
        if (n < 3 || n > 4) return null
        x.r = i(r[3] == "a" ? r.slice(5) : r.slice(4)), x.g = i(g), x.b = i(b), x.a = a ? parseFloat(a) : -1
      } else {
        if (n == 8 || n == 6 || n < 4) return null
        if (n < 6) d = "#" + d[1] + d[1] + d[2] + d[2] + d[3] + d[3] + (n > 4 ? d[4] + d[4] : "")
        d = i(d.slice(1), 16)
        if (n == 9 || n == 5) x.r = d >> 24 & 255, x.g = d >> 16 & 255, x.b = d >> 8 & 255, x.a = m((d & 255) / 0.255) / 1000
        else x.r = d >> 16, x.g = d >> 8 & 255, x.b = d & 255, x.a = -1
      }
      return x
    };
    h = c0.length > 9, h = a ? c1.length > 9 ? true : c1 == "c" ? !h : false : h, f = this.pSBCr(c0), P = p < 0, t = c1 && c1 != "c" ? this.pSBCr(c1) : P ? {
      r: 0,
      g: 0,
      b: 0,
      a: -1
    } : {r: 255, g: 255, b: 255, a: -1}, p = P ? p * -1 : p, P = 1 - p
    if (!f || !t) return null
    if (l) r = m(P * f.r + p * t.r), g = m(P * f.g + p * t.g), b = m(P * f.b + p * t.b)
    else r = m((P * f.r ** 2 + p * t.r ** 2) ** 0.5), g = m((P * f.g ** 2 + p * t.g ** 2) ** 0.5), b = m((P * f.b ** 2 + p * t.b ** 2) ** 0.5)
    a = f.a, t = t.a, f = a >= 0 || t >= 0, a = f ? a < 0 ? t : t < 0 ? a : a * P + t * p : 0
    if (h) return "rgb" + (f ? "a(" : "(") + r + "," + g + "," + b + (f ? "," + m(a * 1000) / 1000 : "") + ")"
    else return "#" + (4294967296 + r * 16777216 + g * 65536 + b * 256 + (f ? m(a * 255) : 0)).toString(16).slice(1, f ? undefined : -2)
  }
  /* eslint-enable */
  }
}
