import Lget from 'lodash/get'
import Lset from 'lodash/set'
import { isEmpty } from 'lodash'
import { createWarningNotification } from '@/lib/unnotificationsqueue'
import { unNumberFmt, unNumberParse } from '@/lib/uncrudtable/mixins/filters'

export default {
  name: 'HierarchiesPivotTableCell',
  props: {
    cell: {
      type: Object,
      description: 'Object containing both the cell fields data and its respective metadata',
      required: true
    },
    cellUpdating: {
      type: [String, Number],
      description: 'Id of cell being updated, as to show a spinner in said cell',
      required: true
    },
    customCellFormatters: {
      type: Object,
      description: '[field name] -> {fmt: formatter (arrow func), suffix: (Which can be empty)',
      default: null
    },
    customCellEdition: {
      type: [Function, null],
      description: 'Callback to customise cell data edition',
      default: null
    }
  },
  data () {
    return {
      preSaveCell: {},
      cellBeforeUpdate: {},
      elementsChanged: []
    }
  },
  computed: {
    /**
   * Used to 'extract' the cell's metadata from the cell prop
   * @returns {*}
   */
    cellMetadata () {
      return this.cell.metadata
    },
    /**
   * Used to render the cell values in the table (The default slot)
   * @returns {*}
   */
    cellValues () {
      return this.cellMetadata.map(field => {
        const cellValue = Lget(this.cell, field.value)
        const defaultFormat = {
          fmt: v => unNumberFmt(v, 2)
        }
        const { fmt, suffix } = Lget(this.customCellFormatters, field.value, defaultFormat)
        return {
          value: fmt(cellValue),
          suffix,
          field: field.value,
          editable: field.editable
        }
      })
    },
    /**
   * Used to generate the text-fields in the edition dialog
   * @returns {{}}
   */
    dialogElementObjects () {
      let elements = {}
      if (isEmpty(this.preSaveCell)) {
        return {}
      } else {
        this.cellMetadata.forEach((field, i) => {
          if (field.editable) elements[field.value] = { label: field.label, value: this.cellValues[i].value, disabled: !field.editable }
        })
        return elements
      }
    }
  },
  methods: {
    /**
   * Event handler to save cell information in a variable when opening the edition dialog
   */
    onOpenCell () {
      let elements = {}
      this.cellMetadata.forEach(field => {
        let cellValue = Lget(this.cell, field.value)
        let fixedValue = cellValue && Number(cellValue)
        Lset(elements, field.value, fixedValue)
      })
      this.preSaveCell = elements
    },
    /**
   * Event handler for the text-fields' edition. Inserts and removes elements from 'this.elementsChanged'
   * @param inputValue {string} inputted value
   * @param field {string} edited field name
   */
    onEditElement (inputValue, field) {
      let value = unNumberParse(inputValue)
      if (this.customCellEdition) {
        value = this.customCellEdition(field, value)
      }
      if (inputValue === '') {
        Lset(this.preSaveCell, field, inputValue)
      } else {
        Lset(this.preSaveCell, field, value)
      }
      const fieldChanged = this.elementsChanged.includes(field)
      let cellValue = this.cellValues.find(cellValueField => cellValueField.field === field).value
      let fieldValueInCell = cellValue !== '-' ? unNumberParse(cellValue) : ''
      if (!fieldChanged && (value !== fieldValueInCell || inputValue !== fieldValueInCell)) {
        this.elementsChanged.push(field)
      } else if (inputValue === fieldValueInCell || value === fieldValueInCell) {
        let index = this.elementsChanged.findIndex(element => element === field)
        this.elementsChanged.splice(index, 1)
      }
    },
    /**
   * Event handler for accepting a dialog edition. Triggers save event to TableData
   */
    onSaveCell () {
      if (this.$refs.form.validate()) {
        const elementsChanged = this.elementsChanged
        this.cellBeforeUpdate = Object.assign({}, this.cell)
        this.$emit('update:cell', { ...this.cell, ...this.preSaveCell, elementsChanged })
        this.$emit('saveCell')
      // this.$emit('saveCell', { ...this.cell, ...this.preSaveCell, to })
      } else {
        this.$store.dispatch('addNotification', createWarningNotification(this.$t('general.valuesNotValidNotSaved')))
      }
    },
    /**
   * To be used whenever a save/update fails, as to reset to previous cell value
   * @param cellId {number}
   */
    undoUpdate (cellId) {
      this.$emit('update:cell', { ...this.cellBeforeUpdate, isCellUpdated: false })
    },
    /**
   * Event handler to clear edition dialog data.
   */
    onCloseDialog () {
      this.elementsChanged = []
      this.preSaveCell = {}
    },
    /**
   * Validation rule method: checks if number format is correct
   * @param v {string} inputted value
   * @returns {boolean|string}
   */
    validateNumbers (v) {
      return v === '-' || !isNaN(unNumberParse(v)) || this.$t('dialogs.invalidValue')
    }
  }
}
