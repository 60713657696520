<script>
import { Bar } from 'vue-chartjs'

/**
 * props and options docs https://www.chartjs.org/docs/latest/charts/bar.html
 */
export default {
  extends: Bar,
  props: {
    chartData: {
      type: Object,
      default: null
    },
    options: {
      type: Object,
      default: null
    }
  },
  mounted () {
    this.renderChart(this.chartData, this.options)
  }
}
</script>
