var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',{staticClass:"hierarchiesPivotTableData mt-5 mb-2",class:_vm.adaptableStyles,attrs:{"items":_vm.orderedRows,"headers":_vm.headersWithRowName,"items-per-page":Infinity,"loading":_vm.loadingTable || !!_vm.cellUpdating,"height":_vm.tableHeight,"fixed-header":"","hide-default-footer":"","disable-sort":"","dense":""},scopedSlots:_vm._u([_vm._l((_vm.headersSlots),function(ref){
var headerSlot = ref.headerSlot;
var fields = ref.fields;
return {key:headerSlot,fn:function(ref){
var header = ref.header;
return [_c('div',{staticClass:"pivotTableSuperHeader text-center pt-3"},[_vm._v(_vm._s(header.text))]),_c('div',{staticClass:"pivotTableCellArray --subheader"},_vm._l((fields),function(field){return _c('div',{key:field.value,staticClass:"pivotTableCellElement"},[_vm._v(" "+_vm._s(field.label)+" ")])}),0)]}}}),{key:"item",fn:function(ref){
var item = ref.item;
return [_c('tr',{style:(_vm.computeRowLevelClass(item))},_vm._l((_vm.itemsSlots),function(ref){
var itemSlot = ref.itemSlot;
var value = ref.value;
return _c('td',{key:value,class:_vm.stickyColumnStyles},[(value === 'name')?_c('div',{staticClass:"rowIdentifier"},[_c('span',[_vm._v(_vm._s(item[value]))])]):(!!item[value])?_c('HierarchiesPivotTableCell',{ref:("cell" + (item[value].id)),refInFor:true,staticClass:"HierarchiesPivotTableCell",attrs:{"cell":item[value],"cellUpdating":_vm.cellUpdating,"customCellFormatters":_vm.customCellFormatters,"customCellEdition":_vm.customCellEdition},on:{"update:cell":function($event){return _vm.$set(item, value, $event)},"saveCell":function($event){return _vm.onSaveCell(item, value)}}}):_vm._e()],1)}),0)]}}],null,true)})}
var staticRenderFns = []

export { render, staticRenderFns }