var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.cell.editableCell)?_c('v-edit-dialog',{attrs:{"large":"","save-text":_vm.$t('general.accept'),"cancel-text":_vm.$t('general.cancel'),"persistent":""},on:{"save":_vm.onSaveCell,"open":_vm.onOpenCell,"close":_vm.onCloseDialog},scopedSlots:_vm._u([{key:"input",fn:function(){return [_c('v-form',{ref:"form",staticClass:"pt-5",on:{"submit":function($event){$event.preventDefault();}}},_vm._l((_vm.dialogElementObjects),function(ref,field){
var label = ref.label;
var value = ref.value;
var disabled = ref.disabled;
return _c('v-text-field',{key:label,attrs:{"value":value,"rules":!disabled ? [_vm.validateNumbers] : [],"disabled":disabled,"label":label,"autofocus":"","outlined":"","dense":""},on:{"input":function (inputValue) { return _vm.onEditElement(inputValue, field); }}})}),1)]},proxy:true}],null,false,3622955131)},[_c('div',{staticClass:"pivotTableCellArray",class:_vm.cell.isCellUpdated ? '--updated' : ''},[(_vm.cellUpdating === _vm.cell.id)?[_c('v-progress-circular',{attrs:{"color":"primary","indeterminate":"","size":15,"width":2}})]:_vm._l((_vm.cellValues),function(ref,indexChild){
var value = ref.value;
var editable = ref.editable;
var suffix = ref.suffix;
return _c('div',{key:indexChild,staticClass:"pivotTableCellElement",class:editable ? '--editableField' : ''},[_vm._v(" "+_vm._s(value)+_vm._s(suffix)+" ")])})],2)]):_c('div',{staticClass:"pivotTableCellArray",class:_vm.cell.isCellUpdated ? '--updated' : ''},_vm._l((_vm.cellValues),function(ref,indexParent){
var value = ref.value;
var suffix = ref.suffix;
return _c('div',{key:indexParent,staticClass:"pivotTableCellElement",staticStyle:{"font-weight":"bold"}},[_vm._v(" "+_vm._s(value)+_vm._s(suffix)+" ")])}),0)}
var staticRenderFns = []

export { render, staticRenderFns }