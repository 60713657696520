import { capitalize } from '@/apps/core/helpers/utils'
import HierarchiesPivotTableFilters from '../HierarchiesPivotTableFilters/HierarchiesPivotTableFilters.vue'
import HierarchiesPivotTableData from '../HierarchiesPivotTableData/HierarchiesPivotTableData.vue'
import BarChart from '../charts/BarChart'

export default {
  name: 'HierarchiesPivotTableBase',
  components: {
    HierarchiesPivotTableFilters,
    HierarchiesPivotTableData,
    BarChart
  },
  props: {
    hideTable: {
      type: Boolean,
      description: 'To control the displaying and mounting of the table data component',
      default: false
    },
    tableConfig: {
      type: Object,
      description: 'Specifies all the configuration required',
      default: () => {}
    },
    showChart: {
      type: Boolean,
      description: 'Specifies all the configuration required',
      default: false
    },
    adaptableHeight: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      /**
   * These elements correspond to a HierarchyPivotFilter instance (Should be persisted in DB)
   * possible list elements format: {
   *   id: hierarchy-element-id,
   *   hierarchy_level: {Normally object, but only id is needed for API call},
   *   withDescendants: true/false,
   *   breadcrumb: 'String / for / display'
   * }
   */
      selectedRows: [],
      selectedColumns: [],
      selectedFilters: [],
      canShow: false
    }
  },
  computed: {
    isAdaptableHeight () {
      return this.adaptableHeight
    },

    rowsEndpoints () {
      return this.$props.tableConfig
    },
    chartInformation () {
      if (this.$props.showChart) {
        this.$props.hideTable = true
        return {
          labels: this.label,
          datasets: [this.dataset]
        }
      } else {
        this.$props.hideTable = false
      }
    }
  },
  methods: {
    /**
   * Event handler to add filter element to its respective selection list
   * @param axis {String} refers to rows, columns or filters
   * @param pivotFilter {Object} filter being pushed
   */
    addSelected (axis, pivotFilter) {
      let componentField = `selected${capitalize(axis)}`
      this[componentField].push(pivotFilter)
    },
    /**
   * Event handler to remove filter element from its respective selection list
   * @param axis {String} refers to rows, columns or filters
   * @param pivotFilter {Object} filter being removed
   */
    removeSelected (axis, pivotFilter) {
      let componentField = `selected${capitalize(axis)}`
      let index = this[componentField].findIndex(filter => filter.id === pivotFilter.id)
      this[componentField].splice(index, 1)
    },
    /**
   * Basically a 'refreshData' for all fields
   */
    reloadDataFromChild () {
      this.$refs.hierarchiesPivotTableDataComponent && this.$refs.hierarchiesPivotTableDataComponent.fetchData()
    },
    /**
   * Refetches the rows, columns and/or cells endpoints depending on parameters changing
   * @param changedProperty {string} 'row', 'column' or 'all', depending on which endpoint changes
   */
    refreshData (changedProperty) {
      if (!this.hideTable) {
        this.$nextTick(() => {
          this.$refs.hierarchiesPivotTableDataComponent && this.$refs.hierarchiesPivotTableDataComponent.refreshData(changedProperty)
        })
      }
    },
    /**
   * When PATCHING returns a list of updated cells
   * @param updatedCells {Array} list of updated cells to splice into table data
   */
    refreshUpdatedCells (updatedCells) {
      this.$refs.hierarchiesPivotTableDataComponent && this.$refs.hierarchiesPivotTableDataComponent.refreshUpdatedCells(updatedCells)
    },
    /**
   * Notifies table data to turn off loaders after data refreshes
   */
    isUpdateFinished () {
      this.$refs.hierarchiesPivotTableDataComponent && this.$refs.hierarchiesPivotTableDataComponent.isUpdateFinished()
    },
    /**
   * Event handler for chart data changes
   * @param chartData
   */
    onHandleChartData (chartData) {
      this.label = chartData.labels
      this.dataset = chartData.datasets[0]
    },
    /**
   * To be used whenever a save/update fails, as to reset to previous cell value
   * @param cellId {number}
   */
    undoUpdate (cellId) {
      this.$refs.hierarchiesPivotTableDataComponent && this.$refs.hierarchiesPivotTableDataComponent.undoUpdate(cellId)
    }
  }
}
